import React from "react"

import { Link} from "gatsby"

import logo from "./../../slike/logo.png"

import { useRef} from "react"

import AnimatedHeading from "./AnimatedHeading"

import useOnScreen from "../hooks/useOnScreen"

import "../styles/whereAreWeSection.css"

export default function WhereAreWeSection() {
		
	const ref = useRef()
	const sectionVisible = useOnScreen(ref)
	
	return (
		<section ref={ref} className={`kn-where-are-we-section${ sectionVisible ? " kn-section-visible" : ""}`}>
			
			<div className="kn-where-are-we-outer-section">
				
				<div className="kn-where-are-we-maps-container">
					
					<iframe title="Where are we?" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2872.8517676938086!2d15.450870315760804!3d43.9417413417419!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1334cb363dbd5905%3A0xaaafa4547252787b!2sGeo%20Kontura%20d.o.o.!5e0!3m2!1shr!2shr!4v1633693719837!5m2!1shr!2shr" className="kn-where-are-we-maps" allowfullscreen="" loading="lazy"></iframe>
					
				</div>
				
				<div className="kn-where-are-we-info-container">
					
					<AnimatedHeading  className="kn-where-are-we-title" delay={600}  delayBetween={20}>
						<h2 className="kn-where-are-we-title">Gdje se nalazimo?</h2>
					</AnimatedHeading>				
					
					<Link to="/" className="kn-where-are-we-logo-link"><img className="kn-where-are-we-logo" src={logo} alt="logo"/></Link>
					
					<ul className="kn-where-are-we-list">
						
						<li>
							<AnimatedHeading className="fas fa-user" delay={250}  delayBetween={20}>
								<i></i>
							</AnimatedHeading>
							<span>
								<AnimatedHeading  className="kn-where-are-we-owner" delay={300} delayBetween={20}>
									<h3 className="kn-where-are-we-owner">MARIO JOVIĆ</h3>
								</AnimatedHeading>
								<AnimatedHeading className="kn-where-are-we-owner-title" delay={520}  delayBetween={20}>
									<h4 className="kn-where-are-we-owner-title"> mag.ing.geod. et geoinf., direktor</h4>
								</AnimatedHeading>
							</span>
						</li>
						<li>
							<AnimatedHeading className="fas fa-map-marked-alt" delay={350}  delayBetween={20}>
								<i></i>
							</AnimatedHeading>
							<AnimatedHeading  className="kn-where-are-we-location" delay={400}  delayBetween={20}>
								<a href="https://goo.gl/maps/bwKdeUo3xmAKq6CK7" className="kn-where-are-we-location">Ul. dr. Franje Tuđmana 82, Biograd n/M, BURE Centar, 1. kat</a>
							</AnimatedHeading>
						</li>
						<li>
							<AnimatedHeading className="fas fa-phone" delay={450}  delayBetween={20}>
								<i></i>
							</AnimatedHeading>
							<AnimatedHeading className="kn-where-are-we-phone" delay={500}  delayBetween={20}>
								<a href="tel:+385 97 735 1423" className="kn-where-are-we-phone"> +385 (0) 97 735 1423</a>
							</AnimatedHeading>
						</li>
						<li>
							<AnimatedHeading className="fas fa-envelope" delay={500}  delayBetween={20}>
								<i></i>
							</AnimatedHeading>
							<AnimatedHeading className="kn-where-are-we-mail" delay={550}  delayBetween={20}>
								<a href="mailto:mario@geokontura.hr" className="kn-where-are-we-mail">mario@geokontura.hr</a>
							</AnimatedHeading>
						</li>		
					</ul>
				</div>
				
			</div>
			
		</section>
	)
}